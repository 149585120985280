<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showEditButton
    @edit="(id) => onEdit('SupportRequestEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'SupportRequests',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'SupportRequests',
      tableCaption: 'Заявки',
      breadcrumbs: [
        {
          text: 'Заявки',
          route: { name: 'RequestsMain' },
        },
        {
          text: 'Заявки',
        },
      ],
      tableHeaders: [
        { text: 'Номер', alias: 'number', order: 'number' },
        { text: 'Название', alias: 'name', order: 'name' },
        { text: 'Имя клиента', alias: 'customer', order: 'customerName' },
        { text: 'Имя автора', alias: 'author', order: 'author' },
        { text: 'Дата создания', alias: 'added', order: 'added' },
        {
          text: 'Заблокирован',
          alias: 'lockedBy',
          order: 'lockedBy',
        },
        {
          text: 'ИС начислений',
          alias: 'settingsIntegrationCustomType',
          order: 'settingsIntegrationCustomType',
        },
        {
          text: 'Общий статус',
          alias: 'initiationStatus',
          order: 'initiationStatus',
        },
        { text: 'Статус', alias: 'status', order: 'status' },
        { text: 'Тип', alias: 'gisType', order: 'gisType' },
        { text: 'План. часы', alias: 'planHours', order: 'planHours' },
        { text: 'Низкая оценка', alias: 'isLowMark', order: 'isLowMark' },
        { alias: 'actions' },
      ],
    };
  },
};
</script>
